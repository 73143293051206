@charset "UTF-8";

@keyframes plyr-progress {
	to {
		background-position: 25px 0;
		background-position: var(--plyr-progress-loading-size, 25px) 0
	}
}

@keyframes plyr-popup {
	0% {
		opacity: .5;
		transform: translateY(10px)
	}

	to {
		opacity: 1;
		transform: translateY(0)
	}
}

@keyframes plyr-fade-in {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.plyr {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	align-items: center;
	direction: ltr;
	display: flex;
	flex-direction: column;
	font-family: inherit;
	font-family: var(--plyr-font-family, inherit);
	font-variant-numeric: tabular-nums;
	font-weight: 400;
	font-weight: var(--plyr-font-weight-regular, 400);
	line-height: 1.7;
	line-height: var(--plyr-line-height, 1.7);
	max-width: 100%;
	min-width: 200px;
	position: relative;
	text-shadow: none;
	transition: box-shadow .3s ease;
	z-index: 0
}

.plyr audio,
.plyr iframe,
.plyr video {
	display: block;
	height: 100%;
	width: 100%
}

.plyr button {
	font: inherit;
	line-height: inherit;
	width: auto
}

.plyr:focus {
	outline: 0
}

.plyr--full-ui {
	box-sizing: border-box
}

.plyr--full-ui *,
.plyr--full-ui :after,
.plyr--full-ui :before {
	box-sizing: inherit
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
	touch-action: manipulation
}

.plyr__badge {
	background: #4a5464;
	background: var(--plyr-badge-background, #4a5464);
	border-radius: 2px;
	border-radius: var(--plyr-badge-border-radius, 2px);
	color: #fff;
	color: var(--plyr-badge-text-color, #fff);
	font-size: 9px;
	font-size: var(--plyr-font-size-badge, 9px);
	line-height: 1;
	padding: 3px 4px
}

.plyr--full-ui ::-webkit-media-text-track-container {
	display: none
}

.plyr__captions {
	animation: plyr-fade-in .3s ease;
	bottom: 0;
	display: none;
	font-size: 13px;
	font-size: var(--plyr-font-size-small, 13px);
	left: 0;
	padding: 10px;
	padding: var(--plyr-control-spacing, 10px);
	position: absolute;
	text-align: center;
	transition: transform .4s ease-in-out;
	width: 100%
}

.plyr__captions span:empty {
	display: none
}

@media (min-width:480px) {
	.plyr__captions {
		font-size: 15px;
		font-size: var(--plyr-font-size-base, 15px);
		padding: 20px;
		padding: calc(var(--plyr-control-spacing, 10px)*2)
	}
}

@media (min-width:768px) {
	.plyr__captions {
		font-size: 18px;
		font-size: var(--plyr-font-size-large, 18px)
	}
}

.plyr--captions-active .plyr__captions {
	display: block
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty)~.plyr__captions {
	transform: translateY(-40px);
	transform: translateY(calc(var(--plyr-control-spacing, 10px)*-4))
}

.plyr__caption {
	background: #000c;
	background: var(--plyr-captions-background, #000c);
	border-radius: 2px;
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
	color: #fff;
	color: var(--plyr-captions-text-color, #fff);
	line-height: 185%;
	padding: .2em .5em;
	white-space: pre-wrap
}

.plyr__caption div {
	display: inline
}

.plyr__control {
	background: #0000;
	border: 0;
	border-radius: 4px;
	border-radius: var(--plyr-control-radius, 4px);
	color: inherit;
	cursor: pointer;
	flex-shrink: 0;
	overflow: visible;
	padding: 7px;
	padding: calc(var(--plyr-control-spacing, 10px)*.7);
	position: relative;
	transition: all .3s ease
}

.plyr__control svg {
	fill: currentColor;
	display: block;
	height: 18px;
	height: var(--plyr-control-icon-size, 18px);
	pointer-events: none;
	width: 18px;
	width: var(--plyr-control-icon-size, 18px)
}

.plyr__control:focus {
	outline: 0
}

.plyr__control:focus-visible {
	outline: 2px dashed #00b2ff;
	outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	outline-offset: 2px
}

a.plyr__control {
	text-decoration: none
}

.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control.plyr__control--pressed .label--not-pressed,
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
a.plyr__control:after,
a.plyr__control:before {
	display: none
}

.plyr--full-ui ::-webkit-media-controls {
	display: none
}

.plyr__controls {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	text-align: center
}

.plyr__controls .plyr__progress__container {
	flex: 1;
	min-width: 0
}

.plyr__controls .plyr__controls__item {
	margin-left: 2.5px;
	margin-left: calc(var(--plyr-control-spacing, 10px)/4)
}

.plyr__controls .plyr__controls__item:first-child {
	margin-left: 0;
	margin-right: auto
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
	padding-left: 2.5px;
	padding-left: calc(var(--plyr-control-spacing, 10px)/4);
}

.plyr__controls .plyr__controls__item.plyr__time {
	padding: 0 5px;
	padding: 0 calc(var(--plyr-control-spacing, 10px)/2)
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,
.plyr__controls .plyr__controls__item.plyr__time+.plyr__time,
.plyr__controls .plyr__controls__item.plyr__time:first-child {
	padding-left: 0;
}

.plyr [data-plyr=airplay],
.plyr [data-plyr=captions],
.plyr [data-plyr=fullscreen],
.plyr [data-plyr=pip],
.plyr__controls:empty {
	display: none
}

.plyr--airplay-supported [data-plyr=airplay],
.plyr--captions-enabled [data-plyr=captions],
.plyr--fullscreen-enabled [data-plyr=fullscreen],
.plyr--pip-supported [data-plyr=pip] {
	display: inline-block
}

.plyr__menu {
	display: flex;
	position: relative
}

.plyr__menu .plyr__control svg {
	transition: transform .3s ease
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
	transform: rotate(90deg)
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
	display: none
}

.plyr__menu__container {
	animation: plyr-popup .2s ease;
	background: #ffffffe6;
	background: var(--plyr-menu-background, #ffffffe6);
	border-radius: 8px;
	border-radius: var(--plyr-menu-radius, 8px);
	bottom: 100%;
	box-shadow: 0 1px 2px #00000026;
	box-shadow: var(--plyr-menu-shadow, 0 1px 2px #00000026);
	color: #4a5464;
	color: var(--plyr-menu-color, #4a5464);
	font-size: 15px;
	font-size: var(--plyr-font-size-base, 15px);
	margin-bottom: 10px;
	position: absolute;
	right: -3px;
	text-align: left;
	white-space: nowrap;
	z-index: 3
}

.plyr__menu__container>div {
	overflow: hidden;
	transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1)
}

.plyr__menu__container:after {
	border: 4px solid #0000;
	border-top-color: #ffffffe6;
	border: var(--plyr-menu-arrow-size, 4px) solid #0000;
	border-top-color: var(--plyr-menu-background, #ffffffe6);
	content: "";
	height: 0;
	position: absolute;
	right: 14px;
	right: calc(var(--plyr-control-icon-size, 18px)/2 + var(--plyr-control-spacing, 10px)*.7 - var(--plyr-menu-arrow-size, 4px)/2);
	top: 100%;
	width: 0
}

.plyr__menu__container [role=menu] {
	padding: 7px;
	padding: calc(var(--plyr-control-spacing, 10px)*.7)
}

.plyr__menu__container [role=menuitem],
.plyr__menu__container [role=menuitemradio] {
	margin-top: 2px
}

.plyr__menu__container [role=menuitem]:first-child,
.plyr__menu__container [role=menuitemradio]:first-child {
	margin-top: 0
}

.plyr__menu__container .plyr__control {
	align-items: center;
	color: #4a5464;
	color: var(--plyr-menu-color, #4a5464);
	display: flex;
	font-size: 13px;
	font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
	padding: 4.66667px 10.5px;
	padding: calc(var(--plyr-control-spacing, 10px)*.7/1.5) calc(var(--plyr-control-spacing, 10px)*.7*1.5);
	-webkit-user-select: none;
	user-select: none;
	width: 100%
}

.plyr__menu__container .plyr__control>span {
	align-items: inherit;
	display: flex;
	width: 100%
}

.plyr__menu__container .plyr__control:after {
	border: 4px solid #0000;
	border: var(--plyr-menu-item-arrow-size, 4px) solid #0000;
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%)
}

.plyr__menu__container .plyr__control--forward {
	padding-right: 28px;
	padding-right: calc(var(--plyr-control-spacing, 10px)*.7*4)
}

.plyr__menu__container .plyr__control--forward:after {
	border-left-color: #728197;
	border-left-color: var(--plyr-menu-arrow-color, #728197);
	right: 6.5px;
	right: calc(var(--plyr-control-spacing, 10px)*.7*1.5 - var(--plyr-menu-item-arrow-size, 4px))
}

.plyr__menu__container .plyr__control--forward:focus-visible:after,
.plyr__menu__container .plyr__control--forward:hover:after {
	border-left-color: initial
}

.plyr__menu__container .plyr__control--back {
	font-weight: 400;
	font-weight: var(--plyr-font-weight-regular, 400);
	margin: 7px;
	margin: calc(var(--plyr-control-spacing, 10px)*.7);
	margin-bottom: 3.5px;
	margin-bottom: calc(var(--plyr-control-spacing, 10px)*.7/2);
	padding-left: 28px;
	padding-left: calc(var(--plyr-control-spacing, 10px)*.7*4);
	position: relative;
	width: calc(100% - 14px);
	width: calc(100% - var(--plyr-control-spacing, 10px)*.7*2)
}

.plyr__menu__container .plyr__control--back:after {
	border-right-color: #728197;
	border-right-color: var(--plyr-menu-arrow-color, #728197);
	left: 6.5px;
	left: calc(var(--plyr-control-spacing, 10px)*.7*1.5 - var(--plyr-menu-item-arrow-size, 4px))
}

.plyr__menu__container .plyr__control--back:before {
	background: #dcdfe5;
	background: var(--plyr-menu-back-border-color, #dcdfe5);
	box-shadow: 0 1px 0 #fff;
	box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
	content: "";
	height: 1px;
	left: 0;
	margin-top: 3.5px;
	margin-top: calc(var(--plyr-control-spacing, 10px)*.7/2);
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 100%
}

.plyr__menu__container .plyr__control--back:focus-visible:after,
.plyr__menu__container .plyr__control--back:hover:after {
	border-right-color: initial
}

.plyr__menu__container .plyr__control[role=menuitemradio] {
	padding-left: 7px;
	padding-left: calc(var(--plyr-control-spacing, 10px)*.7)
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after,
.plyr__menu__container .plyr__control[role=menuitemradio]:before {
	border-radius: 100%
}

.plyr__menu__container .plyr__control[role=menuitemradio]:before {
	background: #0000001a;
	content: "";
	display: block;
	flex-shrink: 0;
	height: 16px;
	margin-right: 10px;
	margin-right: var(--plyr-control-spacing, 10px);
	transition: all .3s ease;
	width: 16px
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after {
	background: #fff;
	border: 0;
	height: 6px;
	left: 12px;
	opacity: 0;
	top: 50%;
	transform: translateY(-50%) scale(0);
	transition: transform .3s ease, opacity .3s ease;
	width: 6px
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before {
	background: #00b2ff;
	background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)))
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:after {
	opacity: 1;
	transform: translateY(-50%) scale(1)
}

.plyr__menu__container .plyr__control[role=menuitemradio]:focus-visible:before,
.plyr__menu__container .plyr__control[role=menuitemradio]:hover:before {
	background: #23282f1a
}

.plyr__menu__container .plyr__menu__value {
	align-items: center;
	display: flex;
	margin-left: auto;
	margin-right: -5px;
	margin-right: calc(var(--plyr-control-spacing, 10px)*.7*-1 - -2px);
	overflow: hidden;
	padding-left: 24.5px;
	padding-left: calc(var(--plyr-control-spacing, 10px)*.7*3.5);
	pointer-events: none
}

.plyr--full-ui input[type=range] {
	-webkit-appearance: none;
	appearance: none;
	background: #0000;
	border: 0;
	border-radius: 26px;
	border-radius: calc(var(--plyr-range-thumb-height, 13px)*2);
	color: #00b2ff;
	color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	display: block;
	height: 19px;
	height: calc(var(--plyr-range-thumb-active-shadow-width, 3px)*2 + var(--plyr-range-thumb-height, 13px));
	margin: 0;
	min-width: 0;
	padding: 0;
	transition: box-shadow .3s ease;
	width: 100%
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
	background: #0000;
	background-image: linear-gradient(90deg, currentColor 0, #0000 0);
	background-image: linear-gradient(to right, currentColor var(--value, 0), #0000 var(--value, 0));
	border: 0;
	border-radius: 2.5px;
	border-radius: calc(var(--plyr-range-track-height, 5px)/2);
	height: 5px;
	height: var(--plyr-range-track-height, 5px);
	-webkit-transition: box-shadow .3s ease;
	transition: box-shadow .3s ease;
	-webkit-user-select: none;
	user-select: none
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	background: #fff;
	background: var(--plyr-range-thumb-background, #fff);
	border: 0;
	border-radius: 100%;
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
	height: 13px;
	height: var(--plyr-range-thumb-height, 13px);
	margin-top: -4px;
	margin-top: calc((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px))/2*-1);
	position: relative;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;
	width: 13px;
	width: var(--plyr-range-thumb-height, 13px)
}

.plyr--full-ui input[type=range]::-moz-range-track {
	background: #0000;
	border: 0;
	border-radius: 2.5px;
	border-radius: calc(var(--plyr-range-track-height, 5px)/2);
	height: 5px;
	height: var(--plyr-range-track-height, 5px);
	-moz-transition: box-shadow .3s ease;
	transition: box-shadow .3s ease;
	user-select: none
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
	background: #fff;
	background: var(--plyr-range-thumb-background, #fff);
	border: 0;
	border-radius: 100%;
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
	height: 13px;
	height: var(--plyr-range-thumb-height, 13px);
	position: relative;
	-moz-transition: all .2s ease;
	transition: all .2s ease;
	width: 13px;
	width: var(--plyr-range-thumb-height, 13px)
}

.plyr--full-ui input[type=range]::-moz-range-progress {
	background: currentColor;
	border-radius: 2.5px;
	border-radius: calc(var(--plyr-range-track-height, 5px)/2);
	height: 5px;
	height: var(--plyr-range-track-height, 5px)
}

.plyr--full-ui input[type=range]::-ms-track {
	color: #0000
}

.plyr--full-ui input[type=range]::-ms-fill-upper,
.plyr--full-ui input[type=range]::-ms-track {
	background: #0000;
	border: 0;
	border-radius: 2.5px;
	border-radius: calc(var(--plyr-range-track-height, 5px)/2);
	height: 5px;
	height: var(--plyr-range-track-height, 5px);
	-ms-transition: box-shadow .3s ease;
	transition: box-shadow .3s ease;
	user-select: none
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
	background: #0000;
	background: currentColor;
	border: 0;
	border-radius: 2.5px;
	border-radius: calc(var(--plyr-range-track-height, 5px)/2);
	height: 5px;
	height: var(--plyr-range-track-height, 5px);
	-ms-transition: box-shadow .3s ease;
	transition: box-shadow .3s ease;
	user-select: none
}

.plyr--full-ui input[type=range]::-ms-thumb {
	background: #fff;
	background: var(--plyr-range-thumb-background, #fff);
	border: 0;
	border-radius: 100%;
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
	height: 13px;
	height: var(--plyr-range-thumb-height, 13px);
	margin-top: 0;
	position: relative;
	-ms-transition: all .2s ease;
	transition: all .2s ease;
	width: 13px;
	width: var(--plyr-range-thumb-height, 13px)
}

.plyr--full-ui input[type=range]::-ms-tooltip {
	display: none
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
	border: 0
}

.plyr--full-ui input[type=range]:focus {
	outline: 0
}

.plyr--full-ui input[type=range]:focus-visible::-webkit-slider-runnable-track {
	outline: 2px dashed #00b2ff;
	outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	outline-offset: 2px
}

.plyr--full-ui input[type=range]:focus-visible::-moz-range-track {
	outline: 2px dashed #00b2ff;
	outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	outline-offset: 2px
}

.plyr--full-ui input[type=range]:focus-visible::-ms-track {
	outline: 2px dashed #00b2ff;
	outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	outline-offset: 2px
}

.plyr__poster {
	background-color: #000;
	background-color: var(--plyr-video-background, var(--plyr-video-background, #000));
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity .2s ease;
	width: 100%;
	z-index: 1
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
	opacity: 1
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
	display: none
}

.plyr__time {
	font-size: 13px;
	font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px))
}

.plyr__time+.plyr__time:before {
	content: "⁄";
	margin-right: 10px;
	margin-right: var(--plyr-control-spacing, 10px)
}

@media (max-width:767px) {
	.plyr__time+.plyr__time {
		display: none
	}
}

.plyr__tooltip {
	background: #fff;
	background: var(--plyr-tooltip-background, #fff);
	border-radius: 5px;
	border-radius: var(--plyr-tooltip-radius, 5px);
	bottom: 100%;
	box-shadow: 0 1px 2px #00000026;
	box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
	color: #4a5464;
	color: var(--plyr-tooltip-color, #4a5464);
	font-size: 13px;
	font-size: var(--plyr-font-size-small, 13px);
	font-weight: 400;
	font-weight: var(--plyr-font-weight-regular, 400);
	left: 50%;
	line-height: 1.3;
	margin-bottom: 10px;
	margin-bottom: calc(var(--plyr-control-spacing, 10px)/2*2);
	opacity: 0;
	padding: 5px 7.5px;
	padding: calc(var(--plyr-control-spacing, 10px)/2) calc(var(--plyr-control-spacing, 10px)/2*1.5);
	pointer-events: none;
	position: absolute;
	transform: translate(-50%, 10px) scale(.8);
	transform-origin: 50% 100%;
	transition: transform .2s ease .1s, opacity .2s ease .1s;
	white-space: nowrap;
	z-index: 2
}

.plyr__tooltip:before {
	border-left: 4px solid #0000;
	border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
	border-right: 4px solid #0000;
	border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
	border-top: 4px solid #fff;
	border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
	bottom: -4px;
	bottom: calc(var(--plyr-tooltip-arrow-size, 4px)*-1);
	content: "";
	height: 0;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 0;
	z-index: 2
}

.plyr .plyr__control:focus-visible .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr__tooltip--visible {
	opacity: 1;
	transform: translate(-50%) scale(1)
}

.plyr .plyr__control:hover .plyr__tooltip {
	z-index: 3
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip {
	left: 0;
	transform: translateY(10px) scale(.8);
	transform-origin: 0 100%
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip:before,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip:before {
	left: 16px;
	left: calc(var(--plyr-control-icon-size, 18px)/2 + var(--plyr-control-spacing, 10px)*.7)
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip {
	left: auto;
	right: 0;
	transform: translateY(10px) scale(.8);
	transform-origin: 100% 100%
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip:before {
	left: auto;
	right: 16px;
	right: calc(var(--plyr-control-icon-size, 18px)/2 + var(--plyr-control-spacing, 10px)*.7);
	transform: translateX(50%)
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip--visible,
.plyr__controls>.plyr__control:first-child+.plyr__control:focus-visible .plyr__tooltip,
.plyr__controls>.plyr__control:first-child+.plyr__control:hover .plyr__tooltip,
.plyr__controls>.plyr__control:first-child:focus-visible .plyr__tooltip,
.plyr__controls>.plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls>.plyr__control:last-child .plyr__tooltip--visible,
.plyr__controls>.plyr__control:last-child:focus-visible .plyr__tooltip,
.plyr__controls>.plyr__control:last-child:hover .plyr__tooltip {
	transform: translate(0) scale(1)
}

.plyr__progress {
	left: 6.5px;
	left: calc(var(--plyr-range-thumb-height, 13px)*.5);
	margin-right: 13px;
	margin-right: var(--plyr-range-thumb-height, 13px);
	position: relative
}

.plyr__progress input[type=range],
.plyr__progress__buffer {
	margin-left: -6.5px;
	margin-left: calc(var(--plyr-range-thumb-height, 13px)*-.5);
	margin-right: -6.5px;
	margin-right: calc(var(--plyr-range-thumb-height, 13px)*-.5);
	width: calc(100% + 13px);
	width: calc(100% + var(--plyr-range-thumb-height, 13px))
}

.plyr__progress input[type=range] {
	position: relative;
	z-index: 2
}

.plyr__progress .plyr__tooltip {
	left: 0;
	max-width: 120px;
	overflow-wrap: break-word
}

.plyr__progress__buffer {
	-webkit-appearance: none;
	background: #0000;
	border: 0;
	border-radius: 100px;
	height: 5px;
	height: var(--plyr-range-track-height, 5px);
	left: 0;
	margin-top: -2.5px;
	margin-top: calc((var(--plyr-range-track-height, 5px)/2)*-1);
	padding: 0;
	position: absolute;
	top: 50%
}

.plyr__progress__buffer::-webkit-progress-bar {
	background: #0000
}

.plyr__progress__buffer::-webkit-progress-value {
	background: currentColor;
	border-radius: 100px;
	min-width: 5px;
	min-width: var(--plyr-range-track-height, 5px);
	-webkit-transition: width .2s ease;
	transition: width .2s ease
}

.plyr__progress__buffer::-moz-progress-bar {
	background: currentColor;
	border-radius: 100px;
	min-width: 5px;
	min-width: var(--plyr-range-track-height, 5px);
	-moz-transition: width .2s ease;
	transition: width .2s ease
}

.plyr__progress__buffer::-ms-fill {
	border-radius: 100px;
	-ms-transition: width .2s ease;
	transition: width .2s ease
}

.plyr--loading .plyr__progress__buffer {
	animation: plyr-progress 1s linear infinite;
	background-image: linear-gradient(-45deg, #23282f99 25%, #0000 0, #0000 50%, #23282f99 0, #23282f99 75%, #0000 0, #0000);
	background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, #23282f99) 25%, #0000 25%, #0000 50%, var(--plyr-progress-loading-background, #23282f99) 50%, var(--plyr-progress-loading-background, #23282f99) 75%, #0000 75%, #0000);
	background-repeat: repeat-x;
	background-size: 25px 25px;
	background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
	color: #0000
}

.plyr--video.plyr--loading .plyr__progress__buffer {
	background-color: #ffffff40;
	background-color: var(--plyr-video-progress-buffered-background, #ffffff40)
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
	background-color: #c1c8d199;
	background-color: var(--plyr-audio-progress-buffered-background, #c1c8d199)
}

.plyr__progress__marker {
	background-color: #fff;
	background-color: var(--plyr-progress-marker-background, #fff);
	border-radius: 1px;
	height: 5px;
	height: var(--plyr-range-track-height, 5px);
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 3px;
	width: var(--plyr-progress-marker-width, 3px);
	z-index: 3
}

.plyr__volume {
	align-items: center;
	display: flex;
	position: relative
}

.plyr__volume input[type=range] {
	margin-left: 5px;
	margin-left: calc(var(--plyr-control-spacing, 10px)/2);
	margin-right: 5px;
	margin-right: calc(var(--plyr-control-spacing, 10px)/2);
	max-width: 90px;
	min-width: 60px;
	position: relative;
	z-index: 2
}

.plyr--audio {
	display: block
}

.plyr--audio .plyr__controls {
	background: #fff;
	background: var(--plyr-audio-controls-background, #fff);
	border-radius: inherit;
	color: #4a5464;
	color: var(--plyr-audio-control-color, #4a5464);
	padding: 10px;
	padding: var(--plyr-control-spacing, 10px)
}

.plyr--audio .plyr__control:focus-visible,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
	background: #00b2ff;
	background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	color: #fff;
	color: var(--plyr-audio-control-color-hover, #fff)
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
	background-color: #c1c8d199;
	background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199))
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
	background-color: #c1c8d199;
	background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199))
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
	background-color: #c1c8d199;
	background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a)
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a)
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a)
}

.plyr--audio .plyr__progress__buffer {
	color: #c1c8d199;
	color: var(--plyr-audio-progress-buffered-background, #c1c8d199)
}

.plyr--video {
	overflow: hidden
}

.plyr--video.plyr--menu-open {
	overflow: visible
}

.plyr__video-wrapper {
	background: #000;
	background: var(--plyr-video-background, var(--plyr-video-background, #000));
	border-radius: inherit;
	height: 100%;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
	aspect-ratio: 16/9
}

@supports not (aspect-ratio:16/9) {

	.plyr__video-embed,
	.plyr__video-wrapper--fixed-ratio {
		height: 0;
		padding-bottom: 56.25%;
		position: relative
	}
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
	border: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%
}

.plyr--full-ui .plyr__video-embed>.plyr__video-embed__container {
	padding-bottom: 240%;
	position: relative;
	transform: translateY(-38.28125%)
}

.plyr--video .plyr__controls {
	background: linear-gradient(#0000, #000000bf);
	background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
	bottom: 0;
	color: #fff;
	color: var(--plyr-video-control-color, #fff);
	left: 0;
	padding: 5px;
	padding: calc(var(--plyr-control-spacing, 10px)/2);
	padding-top: 20px;
	padding-top: calc(var(--plyr-control-spacing, 10px)*2);
	position: absolute;
	right: 0;
	transition: opacity .4s ease-in-out, transform .4s ease-in-out;
	z-index: 3
}

@media (min-width:480px) {
	.plyr--video .plyr__controls {
		padding: 10px;
		padding: var(--plyr-control-spacing, 10px);
		padding-top: 35px;
		padding-top: calc(var(--plyr-control-spacing, 10px)*3.5)
	}
}

.plyr--video.plyr--hide-controls .plyr__controls {
	opacity: 0;
	pointer-events: none;
	transform: translateY(100%)
}

.plyr--video .plyr__control:focus-visible,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
	background: #00b2ff;
	background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	color: #fff;
	color: var(--plyr-video-control-color-hover, #fff)
}

.plyr__control--overlaid {
	background: #00b2ff;
	background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
	border: 0;
	border-radius: 100%;
	color: #fff;
	color: var(--plyr-video-control-color, #fff);
	display: none;
	left: 50%;
	opacity: .9;
	padding: 15px;
	padding: calc(var(--plyr-control-spacing, 10px)*1.5);
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: .3s;
	z-index: 2
}

.plyr__control--overlaid svg {
	left: 2px;
	position: relative
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
	opacity: 1
}

.plyr--playing .plyr__control--overlaid {
	opacity: 0;
	visibility: hidden
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
	display: block
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
	background-color: #ffffff40;
	background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40))
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
	background-color: #ffffff40;
	background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40))
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
	background-color: #ffffff40;
	background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40))
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80)
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80)
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
	box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
	box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80)
}

.plyr--video .plyr__progress__buffer {
	color: #ffffff40;
	color: var(--plyr-video-progress-buffered-background, #ffffff40)
}

.plyr:fullscreen {
	background: #000;
	border-radius: 0 !important;
	height: 100%;
	margin: 0;
	width: 100%
}

.plyr:fullscreen video {
	height: 100%
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
	display: block
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen+svg {
	display: none
}

.plyr:fullscreen.plyr--hide-controls {
	cursor: none
}

@media (min-width:1024px) {
	.plyr:fullscreen .plyr__captions {
		font-size: 21px;
		font-size: var(--plyr-font-size-xlarge, 21px)
	}
}

.plyr--fullscreen-fallback {
	background: #000;
	border-radius: 0 !important;
	bottom: 0;
	height: 100%;
	left: 0;
	margin: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 10000000
}

.plyr--fullscreen-fallback video {
	height: 100%
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
	display: block
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen+svg {
	display: none
}

.plyr--fullscreen-fallback.plyr--hide-controls {
	cursor: none
}

@media (min-width:1024px) {
	.plyr--fullscreen-fallback .plyr__captions {
		font-size: 21px;
		font-size: var(--plyr-font-size-xlarge, 21px)
	}
}

.plyr__ads {
	border-radius: inherit;
	bottom: 0;
	cursor: pointer;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1
}

.plyr__ads>div,
.plyr__ads>div iframe {
	height: 100%;
	position: absolute;
	width: 100%
}

.plyr__ads:after {
	background: #23282f;
	border-radius: 2px;
	bottom: 10px;
	bottom: var(--plyr-control-spacing, 10px);
	color: #fff;
	content: attr(data-badge-text);
	font-size: 11px;
	padding: 2px 6px;
	pointer-events: none;
	position: absolute;
	right: 10px;
	right: var(--plyr-control-spacing, 10px);
	z-index: 3
}

.plyr__ads:empty:after {
	display: none
}

.plyr__cues {
	background: currentColor;
	display: block;
	height: 5px;
	height: var(--plyr-range-track-height, 5px);
	left: 0;
	opacity: .8;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 3px;
	z-index: 3
}

.plyr__preview-thumb {
	background-color: #fff;
	background-color: var(--plyr-tooltip-background, #fff);
	border-radius: 8px;
	border-radius: var(--plyr-menu-radius, 8px);
	bottom: 100%;
	box-shadow: 0 1px 2px #00000026;
	box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
	margin-bottom: 10px;
	margin-bottom: calc(var(--plyr-control-spacing, 10px)/2*2);
	opacity: 0;
	padding: 3px;
	pointer-events: none;
	position: absolute;
	transform: translateY(10px) scale(.8);
	transform-origin: 50% 100%;
	transition: transform .2s ease .1s, opacity .2s ease .1s;
	z-index: 2
}

.plyr__preview-thumb--is-shown {
	opacity: 1;
	transform: translate(0) scale(1)
}

.plyr__preview-thumb:before {
	border-left: 4px solid #0000;
	border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
	border-right: 4px solid #0000;
	border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
	border-top: 4px solid #fff;
	border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
	bottom: -4px;
	bottom: calc(var(--plyr-tooltip-arrow-size, 4px)*-1);
	content: "";
	height: 0;
	left: calc(50% + var(--preview-arrow-offset));
	position: absolute;
	transform: translateX(-50%);
	width: 0;
	z-index: 2
}

.plyr__preview-thumb__image-container {
	background: #c1c8d1;
	border-radius: 7px;
	border-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
	overflow: hidden;
	position: relative;
	z-index: 0
}

.plyr__preview-thumb__image-container img,
.plyr__preview-thumb__image-container:after {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%
}

.plyr__preview-thumb__image-container:after {
	border-radius: inherit;
	box-shadow: inset 0 0 0 1px #00000026;
	content: "";
	pointer-events: none
}

.plyr__preview-thumb__image-container img {
	max-height: none;
	max-width: none
}

.plyr__preview-thumb__time-container {
	background: linear-gradient(#0000, #000000bf);
	background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
	border-bottom-left-radius: 7px;
	border-bottom-left-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
	border-bottom-right-radius: 7px;
	border-bottom-right-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
	bottom: 0;
	left: 0;
	line-height: 1.1;
	padding: 20px 6px 6px;
	position: absolute;
	right: 0;
	z-index: 3
}

.plyr__preview-thumb__time-container span {
	color: #fff;
	font-size: 13px;
	font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px))
}

.plyr__preview-scrubbing {
	bottom: 0;
	filter: blur(1px);
	height: 100%;
	left: 0;
	margin: auto;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity .3s ease;
	width: 100%;
	z-index: 1
}

.plyr__preview-scrubbing--is-shown {
	opacity: 1
}

.plyr__preview-scrubbing img {
	height: 100%;
	left: 0;
	max-height: none;
	max-width: none;
	object-fit: contain;
	position: absolute;
	top: 0;
	width: 100%
}

.plyr--no-transition {
	transition: none !important
}

.plyr__sr-only {
	clip: rect(1px, 1px, 1px, 1px);
	border: 0 !important;
	height: 1px !important;
	overflow: hidden;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important
}

.plyr [hidden] {
	display: none !important
}

/* Custom Player Styles */
.custom-player-container {
	position: relative;
	width: 100%;
}

.custom-player-wrapper {
	position: relative;
	width: 100%;
	background-color: black;
}

.custom-player {
	position: relative;
	width: 100%;
	/* Add these new styles for aspect ratio handling */
}

.custom-player .plyr {
	position: relative;
	padding-bottom: 56.25%; /* Default 16:9 ratio */
	height: 0;
}

.custom-player .plyr video, 
.custom-player .plyr iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}

.custom-player .plyr__video-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: black;
}

.custom-player .plyr__control--overlaid {
	display: none !important;
}

.custom-player .plyr--paused .plyr__controls__item--play {
	opacity: 1;
}

/* Override Plyr's default menu styles */
.custom-player .plyr__menu__container {
	transition: all 0.15s ease !important;
	background-color: rgba(28, 28, 29, 0.7) !important;
}

/* You might also want to adjust the sub-menus */
.custom-player .plyr__menu__container .plyr__control:hover {
	background-color: rgba(216, 158, 0, 0.7) !important; /* Use your gold color for hover states */
}

/* Override Plyr's default control animations */
.custom-player .plyr__controls {
	transition: opacity 0.2s ease !important;
	transform: none !important;  /* Disable transform animations */
}

.custom-player .plyr--video.plyr--hide-controls .plyr__controls {
	opacity: 0 !important;
	transform: none !important;  /* Disable transform animations */
	pointer-events: none;
}

/* Play/Pause overlay animation */
@keyframes scaleIn {
	0% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(0.5);
	}
	50% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1.1);
	}
	100% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(1.2);
	}
}

/* Play/pause overlay styles with lighter background */
.custom-player .player-state-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1500;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
}

.custom-player .plyr--fullscreen .player-state-overlay {
	z-index: 9999;
	width: 120px;
	height: 120px;
}

.custom-player .plyr--fullscreen .player-state-overlay svg {
	width: 60px;
	height: 60px;
}

.custom-player .player-state-overlay svg {
	width: 40px;
	height: 40px;
	color: white;
}

.custom-player .player-state-overlay.show-animation {
	animation: scaleIn 0.5s ease-out forwards;
}

.custom-player .plyr__control {
	transition: all 0.4s ease !important;
	background-color: transparent !important;
}

.custom-player .plyr__control:hover {
	background-color: transparent !important;
}

.custom-player .plyr__control:hover svg {
	color: #d89e00 !important;
}

.custom-player .plyr__control--pressed {
	background-color: transparent !important;
}

.custom-player .plyr__control--pressed svg {
	color: #ffffff !important;
}

/* Specific for the Play/Pause button in the control bar */
.custom-player .plyr__controls [data-plyr="play"]:hover svg {
	color: #d89e00 !important;
}

/* For all SVG Icons in controls */
.custom-player .plyr__controls svg {
	transition: color 0.3s ease, transform 0.3s ease;
	color: rgba(255, 255, 255, 0.8);
}

.custom-player .plyr__menu__container {
	transition: all 0.15s ease !important;
}

.custom-player .plyr__progress__buffer {
	position: absolute;
	left: 0;
	top: 65%;
	transform: translateY(-50%);
	height: 4px;
	background-color: rgba(255, 255, 255, 0.35);
	transition: width 0.2s ease;
}

/* Cursor pointer styles */
.custom-player .plyr__progress,
.custom-player .plyr__progress__container,
.custom-player .plyr__progress input[type="range"],
.custom-player .plyr__progress .plyr__tooltip,
.custom-player .plyr__volume,
.custom-player .plyr__volume input[type="range"],
.custom-player .plyr__control[data-plyr="mute"] {
	cursor: pointer;
}

/* HD badge styles */
.custom-player .plyr__control[data-plyr="settings"] {
	position: relative;
}

.custom-player .hd-badge {
	position: absolute;
	top: 2px;
	right: -2px;
	background-color: #d89e00;
	color: black;
	font-size: 8px;
	font-weight: bold;
	padding: 1px 2px;
	border-radius: 2px;
	pointer-events: none;
	z-index: 10;
	line-height: 1;
	letter-spacing: 0px;
}

/* Fast-forward indicator */
.custom-player .fast-forward-indicator {
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(28, 28, 29, 0.8);
	color: white;
	padding: 6px 12px;
	border-radius: 20px; /* More rounded corners for button-like appearance */
	font-size: 14px;
	font-weight: bold;
	z-index: 1500;
	opacity: 0;
	transition: opacity 0.3s ease;
	pointer-events: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* Add shadow for better visibility */
}

.custom-player .fast-forward-indicator.visible {
	opacity: 1;
}

/* Variable for Plyr menu background */
.custom-player {
	--plyr-menu-background: rgba(28, 28, 29, 0.7);
}

/* Iframe specific styles */
.custom-player-iframe {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%; /* 16:9 aspect ratio */
	height: 0;
	background-color: black;
}

.custom-player-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Custom Context Menu Styles - updated to ensure proper z-index and positioning */
.custom-context-menu {
  position: fixed;
  background-color: rgba(28, 28, 29, 0.95); /* Made slightly darker for better visibility */
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  padding: 6px 0;
  min-width: 180px;
  z-index: 10000; /* Increased z-index to ensure it's above other elements */
  animation: contextMenuFadeIn 0.15s ease-in-out;
  transform-origin: top left;
}

@keyframes contextMenuFadeIn {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.context-menu-item {
  padding: 8px 16px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  transition: background-color 0.1s ease;
  font-weight: bold;
  pointer-events: auto; /* Ensure pointer events are enabled */
}

.context-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Copied notification styles */
.copied-notification {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 10000;
  animation: fadeInOut 2s ease;
  font-weight: bold;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(-10px); }
  15% { opacity: 1; transform: translateY(0); }
  85% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px); }
}

/* YouTube-style player layout */
.custom-player .plyr__controls {
	display: flex;
	flex-wrap: wrap;
	padding: 4px 10px !important;
	background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75)) !important;
	justify-content: space-between; /* This creates two groups - left and right */
}

/* Make progress container go full width at the top */
.custom-player .plyr__progress__container {
	flex: 1 1 100% !important;
	width: 100% !important;
	margin-bottom: 10px;
	padding-left: 0 !important;
	min-width: 0;
	position: relative;
	order: -1; /* Put this first in the flex order */
}

/* Adjust progress bar height and appearance */
.custom-player .plyr__progress {
	left: 0;
	margin-right: 0;
	width: 100%;
}

.custom-player .plyr__progress input[type=range],
.custom-player .plyr__progress__buffer {
	margin-left: 0;
	margin-right: 0;
	width: 100%;
}

/* Create left and right control groups */
.custom-player .plyr__controls::after {
	content: "";
	flex: 1 1 auto; /* Add flexible space between left and right groups */
}

/* Controls layout after progress bar */
.custom-player .plyr__controls .plyr__controls__item {
	margin-left: 2px !important;
	margin-right: 2px !important;
}

/* Play/pause button alignment - keep at left */
.custom-player .plyr__controls .plyr__controls__item:first-child {
	margin-left: 0 !important;
	margin-right: 5px !important;
	order: 0; /* Ensure it comes first in the left group */
}

/* Volume control comes after play button */
.custom-player .plyr__volume {
	max-width: 80px;
	min-width: 60px;
	margin-right: 5px !important;
	order: 1; /* Make this the second item */
}

/* Time display - keep with left group */
.custom-player .plyr__time {
	order: 2; /* Make this the third item in the left group */
	margin-right: 0 !important; /* Don't push other items to right */
}

/* Settings button positioning - right side */
.custom-player .plyr__menu {
	margin-left: 2px !important;
	margin-right: 5px !important;
	order: 3; /* Position in the right group */
}

/* Fullscreen button always last */
.custom-player [data-plyr="fullscreen"] {
	margin-right: 0 !important;
	order: 4; /* Last item */
}

/* Remove auto margin that pushes items to right */
.custom-player .plyr__controls .plyr__controls__item:first-child {
	margin-right: auto !important; /* Remove this */
}

/* Reset the auto margin to create proper alignment */
.custom-player .plyr__controls .plyr__controls__item {
	margin-right: auto !important; /* Reset this */
}

/* Create proper groups by explicitly positioning the right-side controls */
.custom-player .plyr__menu,
.custom-player [data-plyr="fullscreen"] {
	margin-left: auto !important; /* This pushes these items to the right */
}

/* Fix first item auto-margin */
.custom-player .plyr__controls .plyr__controls__item:first-child {
	margin-right: 5px !important; /* Don't push everything right */
}

/* Ensure the right group stays together */
.custom-player .plyr__menu + [data-plyr="fullscreen"] {
	margin-left: 5px !important; /* Keep proper spacing between right items */
}

/* Adjust tooltip positions */
.custom-player .plyr__tooltip {
	top: -30px;
}

/* Override margins for controls to create proper spacing */
.custom-player .plyr__controls .plyr__controls__item.plyr__progress__container + .plyr__control,
.custom-player .plyr__controls .plyr__control + .plyr__volume {
	margin-left: 5px !important;
}

/* Adjust margins for control items */
.custom-player .plyr__controls .plyr__controls__item.plyr__time {
	padding: 0 5px !important;
	margin-left: 5px !important;
}

/* Ensure controls take appropriate space */
.custom-player .plyr__controls > .plyr__control {
	flex-shrink: 0;
}

.custom-player .plyr__controls > .plyr__time {
	flex-shrink: 0;
}

/* Adjust play animation positioning to account for new layout */
.custom-player .player-state-overlay {
	z-index: 2000; /* Ensure it's above the progress bar */
}

/* YouTube-style player layout - completely revised approach */
.custom-player .plyr__controls {
  display: grid !important; /* Use grid layout for precise control */
  grid-template-columns: auto auto auto 1fr auto auto !important;
  gap: 5px !important;
  padding: 4px 10px !important;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75)) !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

/* Progress bar at the top, full width */
.custom-player .plyr__controls .plyr__progress__container {
  grid-column: 1 / -1 !important; /* Span all columns */
  grid-row: 1 !important;
  width: 100% !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  order: -1 !important;
}

/* Play/pause button - first on the left in second row */
.custom-player .plyr__controls [data-plyr="play"] {
  grid-column: 1 !important;
  grid-row: 2 !important;
  margin: 0 !important;
  justify-self: start !important;
}

/* Volume controls - second from the left */
.custom-player .plyr__controls .plyr__volume {
  grid-column: 2 !important;
  grid-row: 2 !important;
  margin: 0 !important;
  justify-self: start !important;
}

/* Time display - third from the left */
.custom-player .plyr__controls .plyr__time {
  grid-column: 3 !important;
  grid-row: 2 !important;
  margin: 0 !important;
  justify-self: start !important;
  padding: 0 5px !important;
}

/* Empty space in the middle */
.custom-player .plyr__controls::before {
  content: "";
  grid-column: 4 !important;
  grid-row: 2 !important;
}

/* Settings button - second from the right */
.custom-player .plyr__controls .plyr__menu {
  grid-column: 5 !important;
  grid-row: 2 !important;
  margin: 0 !important;
  justify-self: end !important;
}

/* Fullscreen button - rightmost */
.custom-player .plyr__controls [data-plyr="fullscreen"] {
  grid-column: 6 !important;
  grid-row: 2 !important;
  margin: 0 !important;
  justify-self: end !important;
}

/* Override any auto margins that might be affecting layout */
.custom-player .plyr__controls .plyr__controls__item {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Ensure the original "auto" margin is removed */
.custom-player .plyr__controls .plyr__controls__item:first-child {
  margin-right: 0 !important; 
}

/* Remove any other conflicting styles */
.custom-player .plyr__menu,
.custom-player [data-plyr="fullscreen"] {
  margin-left: 0 !important;
}

/* Adjust volume slider width */
.custom-player .plyr__volume input[type=range] {
  max-width: 80px !important; 
  min-width: 50px !important;
}

/* Ensure proper spacing between controls */
.custom-player .plyr__control + .plyr__volume,
.custom-player .plyr__volume ~ .plyr__time {
  margin-left: 5px !important;
}

/* Remove any remaining flexbox auto margins */
.custom-player .plyr__controls [data-plyr="play"],
.custom-player .plyr__controls .plyr__volume,
.custom-player .plyr__controls .plyr__time {
  margin-right: 0 !important;
}

/* Ensure tooltips appear in the right position */
.custom-player .plyr__tooltip {
  top: -30px;
}

/* Adjust play animation positioning */
.custom-player .player-state-overlay {
  z-index: 2000; /* Ensure it's above the progress bar */
}

/* Fix for time display elements overlapping - completely revised approach */
.custom-player .plyr__controls {
  display: grid !important;
  grid-template-columns: auto auto auto auto 1fr auto auto !important;
  gap: 5px !important;
  padding: 4px 10px !important;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75)) !important;
  align-items: center !important;
}

/* Progress bar at the top, full width */
.custom-player .plyr__controls .plyr__progress__container {
  grid-column: 1 / -1 !important;
  grid-row: 1 !important;
  width: 100% !important;
  margin: 0 0 10px 0 !important;
  padding: 0 !important;
}

/* Play/pause button - first on the left in second row */
.custom-player .plyr__controls [data-plyr="play"] {
  grid-column: 1 !important;
  grid-row: 2 !important;
  margin: 0 !important;
}

/* Volume controls - second from the left */
.custom-player .plyr__controls .plyr__volume {
  grid-column: 2 !important;
  grid-row: 2 !important;
  margin: 0 !important;
}

/* Current time (separated from duration) */
.custom-player .plyr__controls .plyr__time--current {
  grid-column: 3 !important;
  grid-row: 2 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  min-width: 40px !important;
}

/* Duration time (separated from current time) */
.custom-player .plyr__controls .plyr__time--duration {
  grid-column: 4 !important;
  grid-row: 2 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  min-width: 40px !important;
}

/* Add slash between current time and duration */
.custom-player .plyr__controls .plyr__time--current:after {
  content: " / ";
  margin-left: 2px;
  opacity: 0.8;
}

/* Empty space in the middle */
.custom-player .plyr__controls::before {
  content: "";
  grid-column: 5 !important;
  grid-row: 2 !important;
}

/* Settings button - second from the right */
.custom-player .plyr__controls .plyr__menu {
  grid-column: 6 !important;
  grid-row: 2 !important;
  justify-self: end !important;
}

/* Fullscreen button - rightmost */
.custom-player .plyr__controls [data-plyr="fullscreen"] {
  grid-column: 7 !important;
  grid-row: 2 !important;
  justify-self: end !important;
}

/* Override any auto margins that might be affecting layout */
.custom-player .plyr__controls .plyr__controls__item {
  margin: 0 !important;
}

/* Make sure time display is properly aligned */
.custom-player .plyr__time {
  font-size: 13px;
  display: inline-block !important;
}

/* Hide the default time separator slash that Plyr adds */
.custom-player .plyr__time+.plyr__time::before {
  display: none !important;
}

/* Ensure proper alignment of all elements */
.custom-player .plyr__controls > * {
  vertical-align: middle !important;
}

/* Ensure volume slider has proper width without getting cut off */
.custom-player .plyr__volume input[type=range] {
  max-width: 80px !important; 
  min-width: 50px !important;
}

/* Make sure the space between elements is consistent */
.custom-player .plyr__control + .plyr__volume {
  margin-left: 5px !important;
}

/* Remove all remaining flexbox auto margins */
.custom-player .plyr__controls [data-plyr="play"],
.custom-player .plyr__controls .plyr__volume,
.custom-player .plyr__controls .plyr__time {
  margin-right: 0 !important;
}

/* Change default color for all player UI elements */
.custom-player .plyr__controls svg,
.custom-player .plyr__menu__container .plyr__control,
.custom-player .plyr__time,
.custom-player .plyr__control,
.custom-player .plyr__menu__container [role=menuitem],
.custom-player .plyr__menu__container [role=menuitemradio] {
  color: #ececec !important;
}

/* Change hover color for all player UI elements */
.custom-player .plyr__controls svg:hover,
.custom-player .plyr__control:hover svg,
.custom-player .plyr__menu__container .plyr__control:hover,
.custom-player .plyr__control:hover,
.custom-player .plyr__menu__container [role=menuitem]:hover,
.custom-player .plyr__menu__container [role=menuitemradio]:hover {
  color: #ffffff !important;
}

/* For all SVG Icons in controls - update the existing rule */
.custom-player .plyr__controls svg {
  transition: color 0.3s ease, transform 0.3s ease;
  color: #ececec !important;
}

/* Update the hover effect for play/pause button */
.custom-player .plyr__controls [data-plyr="play"]:hover svg {
  color: #ffffff !important;
}

/* Ensure the progress bar and volume slider also use the new color */
.custom-player .plyr__progress input[type=range]::-webkit-slider-runnable-track,
.custom-player .plyr__volume input[type=range]::-webkit-slider-runnable-track,
.custom-player .plyr__progress__buffer {
  background-color: rgba(255,255,255,.2) !important;
}

/* Change the thumb color to match the theme */
.custom-player .plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #ececec !important;
}
.custom-player .plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #ececec !important;
}
.custom-player .plyr--full-ui input[type=range]::-ms-thumb {
  background: #ececec !important;
}

/* And ensure progress fills use the new color scheme */
.custom-player .plyr--full-ui input[type=range] {
  color: #d89e00 !important;
}

/* Fix specifically for the HD badge to ensure it keeps its color */
.custom-player .hd-badge {
  background-color: #d89e00;
  color: black;
}

/* Make sure player control outlines use the right color when focused */
.custom-player .plyr__control:focus-visible {
  outline-color: #ececec !important;
}

/* Override menu highlight color */
.custom-player .plyr__menu__container .plyr__control:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #ffffff !important;
}

/* Hover preview bar styles - YouTube-like effect */
.custom-player .plyr__progress__container {
  position: relative;
}

/* Create a container for the hover preview bar */
.custom-player .plyr__progress {
  position: relative;
}

/* Hover preview bar - YouTube-like effect */
.custom-player .plyr__progress__hover-preview {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: rgba(255, 255, 255, .5);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  border-radius: 100px;
}

/* Make the hover preview visible when hovering over the progress container */
.custom-player .plyr__progress__container:hover .plyr__progress__hover-preview {
  opacity: 1;
}

/* Ensure the progress track is positioned above the buffer but below the hover preview */
.custom-player .plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}

/* Make the progress bar slightly taller on hover for better usability */
.custom-player .plyr__progress__container:hover input[type=range]::-webkit-slider-runnable-track {
  height: 6px !important;
}
.custom-player .plyr__progress__container:hover input[type=range]::-moz-range-track {
  height: 6px !important;
}
.custom-player .plyr__progress__container:hover input[type=range]::-ms-track {
  height: 6px !important;
}

/* Also make the buffer bar taller on hover */
.custom-player .plyr__progress__container:hover .plyr__progress__buffer {
  height: 6px !important;
}

/* Ensure the hover preview has the right height */
.custom-player .plyr__progress__container:hover .plyr__progress__hover-preview {
  height: 6px;
}

/* Hover preview bar styles - YouTube-like effect - FIXED POSITIONING */
.custom-player .plyr__progress__container {
  position: relative;
}

/* Create a container for the hover preview bar */
.custom-player .plyr__progress {
  position: relative;
}

/* Hover preview bar - YouTube-like effect with correct positioning */
.custom-player .plyr__progress__hover-preview {
  position: absolute;
  bottom: 0; /* Align to bottom like buffer */
  left: 0;
  height: 5px; /* Match the height of the progress track */
  width: 0;
  background-color: rgba(255, 255, 255, .5);
  z-index: 1; /* Make sure it's below the interactive elements but above the buffer */
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  border-radius: 100px;
  /* Ensure proper vertical positioning to match the buffer bar */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
}

/* Make the hover preview visible when hovering over the progress container */
.custom-player .plyr__progress__container:hover .plyr__progress__hover-preview {
  opacity: 1;
}

/* Ensure the progress track is positioned above the buffer and the hover preview */
.custom-player .plyr__progress input[type=range] {
  position: relative;
  z-index: 3; /* Increase z-index to be above both buffer and hover preview */
}

/* Position the buffer bar between the hover preview and the track */
.custom-player .plyr__progress__buffer {
  z-index: 2; /* Ensures it's above the hover preview but below the track */
}

/* Make the progress bar slightly taller on hover for better usability */
.custom-player .plyr__progress__container:hover input[type=range]::-webkit-slider-runnable-track {
  height: 6px !important;
}
.custom-player .plyr__progress__container:hover input[type=range]::-moz-range-track {
  height: 6px !important;
}
.custom-player .plyr__progress__container:hover input[type=range]::-ms-track {
  height: 6px !important;
}

/* Also make the buffer bar taller on hover */
.custom-player .plyr__progress__container:hover .plyr__progress__buffer {
  height: 6px !important;
}

/* Ensure the hover preview has the right height on hover */
.custom-player .plyr__progress__container:hover .plyr__progress__hover-preview {
  height: 6px;
}

/* Add at the end of the file */

/* Ad Container Styles */
.ad-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
}

.ad-container > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Hide Plyr controls when ad is playing */
.custom-player.ad-playing .plyr__controls {
  display: none !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Plyr custom styles */
:root {
  --plyr-color-main: #d89e00;
  --plyr-video-background: #000;
  --plyr-menu-background: rgba(0, 0, 0, 0.9);
  --plyr-menu-color: #fff;
}

.plyr {
  --plyr-color-main: #d89e00;
  height: 100%;
  width: 100%;
}

.plyr video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.plyr--video .plyr__control--overlaid {
  background: rgba(216, 158, 0, 0.8);
}

.plyr--video .plyr__control--overlaid:hover {
  background: rgba(216, 158, 0, 0.9);
}

.plyr--full-ui input[type=range] {
  color: #d89e00;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(216, 158, 0, 0.5);
}

.plyr--video {
  height: 100%;
}

/* Customize NProgress bar */
#nprogress .bar {
  background: linear-gradient(to right, transparent, #d89e00) !important;
  height: 3px !important;
  position: fixed !important;
  top: 64px !important; /* Height of your AppBar/header */
  z-index: 2000 !important; /* Higher than AppBar z-index */
}

#nprogress .peg {
  box-shadow: 0 0 10px #d89e00, 0 0 5px #d89e00 !important;
  display: none !important; /* Removes the protruding line completely */
}

/* Optional: Add transition for smoother appearance */
#nprogress .bar {
  transition: all 200ms ease;
}

/* For mobile devices where header might be smaller */
@media (max-width: 600px) {
  #nprogress .bar {
    top: 56px !important; /* Standard mobile AppBar height */
  }
}

.MuiBox-root + .MuiContainer-root {
  padding-top: 0 !important;
}
